(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/date/assets/javascripts/date.js') >= 0) return;  svs.modules.push('/components/utils/date/assets/javascripts/date.js');
"use strict";



if (typeof exports === 'object') {
  const trinidad = require('trinidad-core').core;
  dateFns = trinidad.components.require('libs', 'date-fns').api;
}

'use strict';
svs.utils = svs.utils || {};
svs.utils.date = svs.utils.date || {};

svs.utils.date.ShortDateAndTime = function (dateAndTimeString) {
  let alwaysDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let useTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  let useYear = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  let date = new Date(dateAndTimeString);
  if (dateFns.isValid(date)) {
    if (!alwaysDate && (dateFns.isToday(date) || dateFns.isTomorrow(date) || dateFns.isYesterday(date))) {
      date = svs.utils.date.getLocalizedWeekday(dateAndTimeString);
    } else if (useYear) {
      date = dateFns.format(date, 'd/M-yy');
    } else {
      date = dateFns.format(date, 'd/M');
    }
    return "".concat(date).concat(useTime ? ", kl. ".concat(dateFns.format(new Date(dateAndTimeString), 'HH:mm')) : '');
  }
  return '';
};

svs.utils.date.SimpleTime = function (dateAndTimeString) {
  const date = new Date(dateAndTimeString);
  if (dateFns.isValid(date)) {
    return dateFns.format(date, 'HH:mm');
  }
  return '';
};

svs.utils.date.FormatTime = function (format, dateAndTimeString) {
  const date = new Date(dateAndTimeString);
  format = format || 'HH:mm';
  if (dateFns.isValid(date)) {
    return dateFns.format(date, format, {
      locale: dateFns.locales.sv
    });
  }
  return dateFns.format(new Date(), format, {
    locale: dateFns.locales.sv
  });
};

svs.utils.date.SimpleDate = function (dateAndTimeString) {
  if (dateFns.isValid(new Date(dateAndTimeString))) {
    return dateFns.format(dateAndTimeString, 'yyyy-MM-dd');
  }
  return '';
};

svs.utils.date.NaturalDateAndTime = function (dateAndTimeString) {
  const date = dateAndTimeString && new Date(dateAndTimeString);
  if (dateFns.isValid(date)) {
    if (dateFns.isToday(date) || dateFns.isTomorrow(date) || dateFns.isYesterday(date)) {
      return "".concat(svs.utils.date.getLocalizedWeekday(date, true, false), " kl. ").concat(dateFns.format(date, 'HH:mm'));
    }
    return dateFns.format(date, 'd MMM HH:mm');
  }
  return '';
};

svs.utils.date.NaturalDate = function (dateAndTimeString) {
  const date = new Date(dateAndTimeString);
  if (dateFns.isValid(date)) {
    return "".concat(dateFns.format(date, 'd'), " ").concat(date.toLocaleDateString('sv-SE', {
      month: 'long'
    }));
  }
  return '';
};

svs.utils.date.WeekNr = function (datestr) {
  return dateFns.getISOWeek(new Date(datestr));
};

svs.utils.date.getLocalizedWeekday = function (date) {
  let capitalizeFirstLetter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let alwaysDate = arguments.length > 2 ? arguments[2] : undefined;
  date = new Date(date);
  const daysDiff = dateFns.differenceInCalendarDays(new Date(), date);
  let day = dateFns.format(date, 'EEEE', {
    locale: dateFns.locales.sv
  });
  if (dateFns.isToday(date)) {
    day = 'idag';
  } else if (dateFns.isTomorrow(date)) {
    day = 'imorgon';
  } else if (dateFns.isYesterday(date)) {
    day = 'igår';
  }
  if (capitalizeFirstLetter) {
    day = day.charAt(0).toUpperCase() + day.slice(1);
  }
  return "".concat(day).concat(daysDiff > 6 || daysDiff < -6 || alwaysDate ? dateFns.format(date, ' d/M', {
    locale: dateFns.locales.sv
  }) : '');
};

svs.utils.date.getWeekdayAndTime = function (date) {
  if (dateFns.isValid(new Date(date))) {
    return "".concat(svs.utils.date.getLocalizedWeekday(date, true), ", ").concat(dateFns.format(new Date(date), 'HH:mm'));
  }
  return '';
};

svs.utils.date.FormatTimeISO = function (format, dateAndTimeString) {
  const date = svs.utils.date.getDateFromISOString(dateAndTimeString);
  format = format || 'HH:mm';
  if (dateFns.isValid(date)) {
    return dateFns.format(date, format, {
      locale: dateFns.locales.sv
    });
  }
  return dateFns.format(new Date(), format, {
    locale: dateFns.locales.sv
  });
};

svs.utils.date.getDateFromISOString = function (dateAndTimeString) {
  let msie11orLower = false;
  let date;
  const isISODateTimeString = typeof dateAndTimeString === 'string' && (dateAndTimeString.indexOf('T') < -1 || dateAndTimeString.indexOf('-') > -1);
  if (isISODateTimeString) {
    if (typeof exports !== 'object') {

      const ua = svs.utils.date.userAgent();

      const msie = ua.indexOf('MSIE ');

      const trident = ua.indexOf('Trident/');
      if (msie > 0 || trident > 0) {
        msie11orLower = true;
      }
    }
    if (msie11orLower) {
      date = svs.utils.date.parseISODateTimeStringForIE(dateAndTimeString, true, true);
    } else {
      date = dateFns.parseISO(dateAndTimeString);
    }
  } else {
    date = new Date(dateAndTimeString);
  }
  return date;
};

svs.utils.date.parseISODateTimeStringForIE = function (inDate, withSeconds, toDate) {
  if (inDate) {
    if (toDate) {
      const year = inDate.substr(0, 4);
      const month = inDate.substr(5, 2);
      const day = inDate.substr(8, 2);
      const hours = inDate.substr(11, 2);
      const minutes = inDate.substr(14, 2);
      const seconds = withSeconds ? inDate.substr(17, 2) : '0';
      return new Date(year, month - 1, day, hours, minutes, seconds);
    }
    return "".concat(inDate.substr(0, 10), " ").concat(withSeconds ? inDate.substr(11, 8) : inDate.substr(11, 5));
  }
  return undefined;
};

svs.utils.date.userAgent = function () {
  return window.navigator.userAgent;
};

if (typeof exports === 'object') {
  module.exports = svs.utils.date;
}

 })(window);